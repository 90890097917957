import React from "react"

export default function FooterLinks() {

	return (
		<>
			<div className="footer-info">
				<div className="row">
					<div className="col-md-12 col-lg-6 footer-about">
						<h3>ABOUT US</h3>
						<p>SwC Poker is the original Bitcoin poker site. We helped create Bitcoin poker, and remain the largest Bitcoin poker site to this day. With anonymous accounts, low rake, and fast cashouts, SwC Poker offers a player friendly experience that is truly unique in the online poker world.</p>
						<p>Bitcoin poker might sound complicated, but it just refers to an online poker site where poker games are played for Bitcoin. All deposits, cash outs, and poker play is done in Bitcoin. 1 Bitcoin = 1,000,000 chips on SwC,  so we can spread games from micro to high stakes. One of the biggest pain points of online poker has been depositing and cashing out in an easy, fast and trusted way. Bitcoin fixes this, and SwC Poker has been doing it longer than anyone else.</p>
					</div>

					<div className="col-md-12 col-lg-5">
						<div className="hash-signed">
							<p className="text">
								This is SwC Poker Website
							</p>
							<p>SIGNED WITH </p>
							<p className="text">
								13MxJjKtvB5thivNtVY6vXcEkEykiJXQtK
							</p>
						</div>
						<div className="hash-signed">
							<p>SIGNATURE </p>
							<p className="text" style={{ width: '100%', inlineSize: '100%', overflowWrap: 'break-word' }}>
								Hw5GDxUcQO4e08Qkb0jElJmUVl9Q6P+UvHTrTAjD4wvgINZhVWeVrfMRx/KzlpEcpI15Mf7rpe67Dir1vgdJmtc=
							</p>
						</div>
						<div className="get-button">
							<a rel="nofollow" href="https://play.swcpoker.club" className="btn playnow">Play Now</a>
							<a rel="nofollow" href="/download" className="btn btn-download">Download</a>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-links">
				<ul>
					<li>
						<a rel="nofollow" href="/problem-gambling">
							Problem Gambling
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/promotions">
							Promotions
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/faq">
							FAQ
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/updates">
							Updates
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/poker-rules">
							Poker Rules
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/krill-leaderboard">
							Leaderboard
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/affiliates">
							Affiliates
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/hall-of-fame">
							Hall of Fame
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/house-rules">
							House Rules
						</a>
					</li>
					<li>
						<a rel="nofollow" href="/">
							Download
						</a>
					</li>
					<li>
						<a rel="nofollow" href="mailto:support@swcpoker.club" className="footer__link" title="support@swcpoker.club">Support</a>
					</li>
					<li>
						<a rel="nofollow" href="/skins">
							Skins
						</a>
					</li>
				</ul>
			</div>

			<div className="sub-footer text-center">
				Back up email: <a rel="nofollow" href="mailto:swc_support_team@proton.me">swc_support_team@proton.me</a>
			</div>
		</>
	)
}