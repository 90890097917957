import React from 'react';
import LayoutHeader from "./layout-header"
import Copyright from './copyright'
import FooterLinks from './footerlinks';

const Layout = ({ pageName, children }) => {
 
  return ( 
    <>
      <LayoutHeader />

      <main>{children}</main>

      <footer>
        <div className="container">
          <Copyright />
          <FooterLinks />
        </div>

        <div className="topslant">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1320" height="50" viewBox="0 0 1310 1">
            <defs>
              <clipPath id="clip-path">
                <path id="Mask" d="M0,48.5H648.553L676,0h566l27.447,48.5H1920v666H0Z" transform="translate(0 0.5)" fill="#0A1308"></path>
              </clipPath>
            </defs>
            <g id="footer-top" transform="translate(0 -0.5)">
              <path id="Mask-2" data-name="Mask" d="M0,48.5H648.553L676,0h566l27.447,48.5H1920v666H0Z" transform="translate(0 0.5)" fill="#0c0f0b"></path>
            </g>
          </svg>
        </div>
 
      
      </footer> 
    </>  
  )
}

export default Layout
