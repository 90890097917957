exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-[uid]-js": () => import("./../../../src/pages/a/[uid].js" /* webpackChunkName: "component---src-pages-a-[uid]-js" */),
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-game-betting-styles-js": () => import("./../../../src/pages/game-betting-styles.js" /* webpackChunkName: "component---src-pages-game-betting-styles-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../../../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-hand-rankings-js": () => import("./../../../src/pages/hand-rankings.js" /* webpackChunkName: "component---src-pages-hand-rankings-js" */),
  "component---src-pages-house-rules-js": () => import("./../../../src/pages/house-rules.js" /* webpackChunkName: "component---src-pages-house-rules-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-krill-leaderboard-js": () => import("./../../../src/pages/krill-leaderboard.js" /* webpackChunkName: "component---src-pages-krill-leaderboard-js" */),
  "component---src-pages-old-rake-js": () => import("./../../../src/pages/old-rake.js" /* webpackChunkName: "component---src-pages-old-rake-js" */),
  "component---src-pages-poker-rules-js": () => import("./../../../src/pages/poker-rules.js" /* webpackChunkName: "component---src-pages-poker-rules-js" */),
  "component---src-pages-problem-gambling-js": () => import("./../../../src/pages/problem-gambling.js" /* webpackChunkName: "component---src-pages-problem-gambling-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-skins-js": () => import("./../../../src/pages/skins.js" /* webpackChunkName: "component---src-pages-skins-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-root-js": () => import("./../../../src/templates/category-root.js" /* webpackChunkName: "component---src-templates-category-root-js" */),
  "component---src-templates-contentsilo-post-js": () => import("./../../../src/templates/contentsilo-post.js" /* webpackChunkName: "component---src-templates-contentsilo-post-js" */),
  "component---src-templates-faq-post-js": () => import("./../../../src/templates/faq-post.js" /* webpackChunkName: "component---src-templates-faq-post-js" */),
  "component---src-templates-poker-rules-js": () => import("./../../../src/templates/poker-rules.js" /* webpackChunkName: "component---src-templates-poker-rules-js" */),
  "component---src-templates-updates-post-js": () => import("./../../../src/templates/updates-post.js" /* webpackChunkName: "component---src-templates-updates-post-js" */)
}

