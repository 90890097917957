import React from "react"
import Logo from "./logo"
import { StaticImage } from "gatsby-plugin-image"

export default function Copyright() {
  return (
    <div className="footer-logo">
      <div className="swc-icon">
        <Logo aria-label="SwC Poker Home" />
      </div>
      <div className="copyright">
        © {new Date().getFullYear()}  SWC POKER • ALL RIGHTS RESERVED &nbsp; &nbsp;
        <a href="https://twitter.com/SwCPoker" rel="noreferrer" target="_blank" title="SwC Poker on Twitter" className="footer__social-button">
          <StaticImage src="../assets/twitter-icon.svg" alt="swc twitter"
            loading="eager" placeholder="none" width={20} height={20} />
        </a>
        &nbsp;
        <a href="https://bitcointalk.org/index.php?topic=5068812" rel="noreferrer" target="_blank" title="SwC Poker on Bitcoin Talk" className="footer__social-button">
          <StaticImage src="../assets/bitcointalk-icon.svg" alt="swc bitcoin"
            loading="eager" placeholder="none" width={20} height={20} />
        </a>

      </div>
    </div>
  )
}

