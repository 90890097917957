import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { isMobileOnly as isMobile } from 'react-device-detect'
import Logo from "./logo"
import Menu from "./menu"

import KrillKing from "./krillking"
import OnlinePlayers from "./onlineplayers"
import BadBeatJackpot from "./badbeatjackpot"

function LayoutHeader(props) {
    const data = useStaticQuery(
        graphql`
          query getHeaderNavData {
            allWpMenu(filter: {name: {eq: "HeaderNavigation"}}) {
              edges {
                node {
                  id
                  name
                  menuItems {
                    nodes {
                      id
                      label
                      path
                      order
                      databaseId
                      parentId
                    }
                  }
                }
              }
            }
          } 
        `
    );

    const queryResultAll = data.allWpMenu.edges;
    const queryResultMainMenu = queryResultAll.filter(m => m.node.name === "HeaderNavigation").shift().node;
    const mainMenuEntries = queryResultMainMenu.menuItems.nodes.filter(item => item.parentId === null);
    mainMenuEntries.forEach(item => {
        item.path = (item.path.length > 1 && item.path.endsWith('/')) ? item.path.slice(0, -1) : item.path
    });
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const [mobile, setMobile] = useState(isMobile);

    useEffect(() => {
        setMobile(isMobile);
    }, []);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);

    }

    return (
        <div>
            <div className="navbar-top navbar-dark  py-2">
                <div className="container  px-3 px-xl-0">
                    <div className="d-md-flex justify-content-end align-items-center top-nav-links">
                        <div className="d-flex">
                            <div className="me-3c top-nav-social">
                                <span>Customer Service: &nbsp;
                                    <a href="mailto:support@swcpoker.club">support@swcpoker.club</a> | <a href="https://t.me/swcpoker" target="_blank" rel="noreferrer">t.me/swcpoker</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <nav className="navbar navbar-expand-lg">
                    
                    <div className="container">
                        <a className="swc-logo" href="/"><Logo aria-label="SwC Poker Home" /></a>
                        <button className={`hamburger  hamburger--spin ${hamburgerOpen ? 'is-active' : ''}`} type="button" onClick={toggleHamburger}>
                            <span className="hamburger-box" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>

                        <div className={`overlay-contentpush overlay mobile-view ${hamburgerOpen ? " open" : ""}`}>
                            <Menu menu={mainMenuEntries} showHome={true} isMobile={true} setHamburgerOpen={setHamburgerOpen} />
                            <div className="poker-stats">
                                {mobile &&
                                    <div className="container">
                                        <div className="info">
                                            <div className="col-box">
                                                <BadBeatJackpot />
                                            </div>
                                            <div className="col-box">
                                                <OnlinePlayers />
                                            </div>
                                            <div className="col-box">
                                                <KrillKing />
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                        <div className="collapse navbar-collapse ml-auto" id="navmenu">
                            <Menu menu={mainMenuEntries} showHome={true} setHamburgerOpen={setHamburgerOpen} />
                            <div className="get-button">
                                <a href="/download" className="btn btn-download">Download</a>
                                <a href="https://play.swcpoker.club" className="btn btn-playnow">Play Now</a>
                            </div>
                        </div>
                    </div>

                    <div className="slant">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1933.203" height="272.982" viewBox="0 0 1933.203 272.982">
                            <path id="Path_5" data-name="Path 5" d="M0,32.016v192.4H424l26.047,48.564H1470.641l27.561-48.564h435V0H0Z" fill="#033018" />
                        </svg>
                    </div>
                </nav>

                {!mobile &&
                    <div className="poker-stats">
                        <div className="container">
                            <div className="info">
                                <div className="col-box">
                                    <BadBeatJackpot />
                                </div>
                                <div className="col-box">
                                    <OnlinePlayers />
                                </div>
                                <div className="col-box">
                                    <KrillKing />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </header>
        </div>
    );
}

export default LayoutHeader;
